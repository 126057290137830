import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import metakey_45handler_45global from "/app/middleware/metakeyHandler.global.ts";
import restrict_45styleguide_45global from "/app/middleware/restrictStyleguide.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  metakey_45handler_45global,
  restrict_45styleguide_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}