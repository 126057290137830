type ExtractedLocale = {
  langcode?: string
  country?: string
}

export const getLocaleFromPath = (path: string): ExtractedLocale => {
  // Get the first part of the path (e.g. 'en-US').
  const prefix = path.split('/').filter(Boolean)[0]

  const config = useRuntimeConfig()
  const assetsDir = config.app.buildAssetsDir.replace(/\/$/, '')

  if (!prefix || path.startsWith(assetsDir)) {
    return {}
  }

  const lastHyphen = prefix.lastIndexOf('-')
  if (lastHyphen === -1) {
    return {
      langcode: prefix,
    }
  }

  const langcode = prefix.substring(0, lastHyphen)
  const country = prefix.substring(lastHyphen + 1)
  return {
    langcode,
    country,
  }
}
