<template>
  <div
    class="col-start-2 col-end-[-2] w-full overflow-auto overscroll-contain pb-32 lg:overflow-visible"
  >
    <Component
      :is="isSlider ? Swiper : 'div'"
      v-if="collections"
      :class="uniqueCssClass"
      class="inline-flex select-none gap-8 !overflow-visible px-16 lg:block lg:px-0 xxxl:mx-[-264px] xxxl:flex xxxl:gap-24"
      v-bind="attributes"
    >
      <Component
        :is="isSlider ? SwiperSlide : 'div'"
        v-for="item in collections"
        :key="item.id"
        class="pb-12 lg:pb-28"
      >
        <CollectionTeaser
          v-bind="item"
          class="w-[160px] flex-none md:w-[200px] lg:w-auto"
        />
      </Component>
    </Component>
    <div
      v-if="isSlider && navigation"
      class="flex justify-end"
      :class="uniqueCssClass"
    >
      <div class="buttons pt-16">
        <button
          class="navigation-button-prev m-0 px-8"
          aria-label="Previous slide"
        >
          <SpriteSymbol
            name="arrow-left"
            class="h-24 w-24 scale-110 text-grey-dark-01 hover:text-black"
          />
        </button>
        <button class="navigation-button-next m-0 px-8" aria-label="Next slide">
          <SpriteSymbol
            name="arrow-right"
            class="h-24 w-24 scale-110 text-grey-dark-01 hover:text-black"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Scrollbar } from 'swiper/modules'

import CollectionTeaser from '~/components/Collection/Teaser/index.vue'
import 'swiper/scss'

const { viewport, isViewport } = useViewport()

const { collections } = await useWatchCollectionData()

type SwiperProps = InstanceType<typeof Swiper>['$props']

const isSlider = computed(() => isViewport('xxxl', 'xxl', 'xl', 'lg'))

const instance = getCurrentInstance()
const uniqueCssClass = 'slider-' + (instance?.uid ?? 0)

const slidesPerView = computed(() => {
  if (viewport.value === 'xxxl' || viewport.value === 'xxl') {
    return 6
  }
  return 4
})

const props = defineProps<{ navigation?: boolean }>()

const attributes = computed<SwiperProps | undefined>(() => {
  if (isSlider.value) {
    return {
      modules: [Scrollbar, Navigation],
      slidesPerView: slidesPerView.value,
      spaceBetween: 24,
      scrollbar: {
        enabled: true,
        snapOnRelease: false,
        draggable: true,
        horizontalClass: 'swiper-scrollbar',
      },
      navigation: {
        enabled: props.navigation,
        prevEl: `.${uniqueCssClass} .navigation-button-prev`,
        nextEl: `.${uniqueCssClass} .navigation-button-next`,
        disabledClass: 'navigation-button--disabled',
      },
    }
  }
})
</script>

<style lang="scss" scoped>
@import 'swiper/scss';

:deep() {
  @import 'swiper/scss';
  @import 'swiper/scss/scrollbar';
}

:deep(.swiper-scrollbar) {
  @apply w-full bg-grey-light-01;

  border-radius: 0;
  height: 2px;
  left: 0;
  width: 100%;

  .swiper-scrollbar-drag {
    @apply bg-black;

    border-radius: 0;
    height: 2px;
  }
}

.navigation-button {
  &--disabled svg {
    @apply text-grey-mid;
    @apply hover:text-grey-mid;
  }
}

.buttons {
  padding-right: 48px;

  @media screen and (width <= 500px) {
    padding-right: 16px;
  }
}
</style>
